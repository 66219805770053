import { createSlice } from "@reduxjs/toolkit";

const initialState = "Dashboard";

const adminPageSlice = createSlice({
  name: "adminPage",
  initialState,
  reducers: {
    changePage(state, action) {
      return action.payload;
    },
  },
});

export const { changePage } = adminPageSlice.actions;

export default adminPageSlice.reducer;
