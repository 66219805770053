import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { api_url } from "../constants";

function Categories() {
  const [categories, setCategories] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [name, setName] = useState("");

  const fetchCategories = async () => {
    try {
      const res = await axios.get(api_url + "/category");
      setCategories(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleDelete = async (id) => {
    try {
      const code = localStorage.getItem("code");
      await axios.delete(api_url + `/category/${id}`, {
        headers: { Authorization: `Bearer ${code}` },
      });
      fetchCategories();
    } catch (err) {
      console.error(err);
    }
  };

  const handleEdit = (category) => {
    setSelectedCategory(category);
    setName(category.name);
    setShowForm(true);
  };

  const handleAdd = () => {
    setSelectedCategory(null);
    setName("");
    setShowForm(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedCategory) {
        await axios.put(api_url + `/category/${selectedCategory.id}`, {
          name,
          code: localStorage.getItem("code"),
        });
      } else {
        await axios.post(api_url + "/category", {
          name,
          code: localStorage.getItem("code"),
        });
      }
      setShowForm(false);
      fetchCategories();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="w-full mx-auto p-6 mt-4">
      <h1
        className="text-2xl md:text-xl sm:text-xl font-bold text-left mb-2"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        Administrare Categorii
      </h1>
      {showForm ? (
        <motion.form
          onSubmit={handleFormSubmit}
          className="bg-white shadow-md rounded p-6 max-w-lg sm:p-2"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <h2 className="text-xl sm:text-lg md:text-lg mb-4">
            {selectedCategory ? "Editează Categoria" : "Adaugă Categorie"}
          </h2>
          <div className="mb-4">
            <label className="block text-gray-700 mb-1">Nume</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full border rounded px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
              required
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="px-4 py-2 rounded border border-gray-300 text-gray-700 hover:bg-gray-100"
            >
              Anulează
            </button>
            <button
              type="submit"
              className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600"
            >
              {selectedCategory ? "Actualizează" : "Adaugă"}
            </button>
          </div>
        </motion.form>
      ) : (
        <>
          <div className="flex justify-end mb-4">
            <button
              onClick={handleAdd}
              className="bg-blue-500 hover:bg-blue-600 sm:w-full justify-center text-white px-4 py-2 rounded flex items-center"
            >
              <FaPlus className="mr-2" /> Adaugă categorie
            </button>
          </div>
          <div className="grid gap-4 grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-x-6 gap-y-4">
            {categories.map((cat) => (
              <motion.div
                key={cat.id}
                className="bg-white shadow-md rounded p-4 flex justify-between items-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <div>
                  <h2 className="text-lg sm:text-base font-semibold">
                    {cat.name}
                  </h2>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleEdit(cat)}
                    className="text-green-500 hover:text-green-600"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleDelete(cat.id)}
                    className="text-red-500 hover:text-red-600"
                  >
                    <FaTrash />
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Categories;
