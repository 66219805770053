import React, { useState, useRef, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import axios from "axios";
import logo from "../static/images/logo.svg";
import { motion, AnimatePresence } from "framer-motion";

const API_URL = "http://localhost:8000/api/promotions/";

const UserLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activePromotion, setActivePromotion] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Preia promoția activă de pe backend
  useEffect(() => {
    const fetchActivePromotion = async () => {
      try {
        const response = await axios.get(API_URL);
        // Filtrăm promoțiile pentru a găsi pe cea activă (presupunând că doar una poate fi activă)
        const active = response.data.find((promo) => promo.is_active);
        setActivePromotion(active);
      } catch (error) {
        console.error("Eroare la preluarea promoției active:", error);
      }
    };

    fetchActivePromotion();
    // Dacă promoțiile se pot schimba din alte componente, poți adăuga o logică de polling
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <nav className="flex lg:grid sm:grid-cols-1 gap-x-16 sm:px-6 relative lg:grid-cols-[2fr,1fr] md:grid-cols-2 md:grid md:px-10 justify-between gap-y-8 px-32 xl:px-28 lg:px-20 pt-10 items-center">
        <Link
          to="/"
          className="flex items-center sm:mx-auto lg:col-span-2 lg:mx-auto gap-x-2"
        >
          <img src={logo} alt="logo" className="h-19 w-19" />
          <div className="font-extrabold text-4xl leading-7">
            STOP
            <br />
            DURERE
          </div>
        </Link>
        {/* Butonul burger: vizibil doar pe mobil */}
        <motion.button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          whileHover={{ scale: 1.025 }}
          whileTap={{ scale: 0.975 }}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="absolute left-10 top-10 sm:block hidden"
        >
          <GiHamburgerMenu className="text-tertiary text-4xl" />
        </motion.button>
        {/* Meniul pentru ecrane mari */}
        <div className="flex font-bold md:order-3 sm:hidden md:col-span-2 md:justify-center grow gap-x-16 justify-center">
          <Link to="/#methods">Metodele noastre</Link>
          <Link to="/#reviews">Marturii de la clienti</Link>
          {/* Elementul "Terapie" fără dropdown clasic */}
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center focus:outline-none"
            >
              Terapie
              <svg
                className="ml-1 w-4 h-4"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
            <AnimatePresence>
              {isDropdownOpen && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="overflow-hidden absolute left-0 mt-2 w-40 bg-white shadow-lg rounded-md z-10"
                >
                  <Link
                    onClick={() => {
                      setIsDropdownOpen(false);
                    }}
                    to="/terapia-pdt-r"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Terapia PDT-R
                  </Link>
                  <Link
                    onClick={() => {
                      setIsDropdownOpen(false);
                    }}
                    to="/nutritie"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Nutriție
                  </Link>
                  <Link
                    onClick={() => {
                      setIsDropdownOpen(false);
                    }}
                    to="/aromaterapie-bach"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Terapia Bach
                  </Link>
                  <Link
                    onClick={() => {
                      setIsDropdownOpen(false);
                    }}
                    to="/aromaterapie-bach"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Aromaterapie
                  </Link>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
        <div className="md:order-2 sm:hidden">
          <Link to="/posts">
            <motion.button
              whileHover={{ scale: 1.025 }}
              whileTap={{ scale: 0.975 }}
              className="bg-primary text-white text-xl rounded-lg font-bold px-10 py-3 block ml-auto"
            >
              Blog
            </motion.button>
          </Link>
        </div>
      </nav>
      {(activePromotion && <div className="mb-6"></div>) || (
        <div className="mb-10"></div>
      )}
      {/* Promoția activă (afișată ca o linie subțire full width) */}
      <AnimatePresence>
        {activePromotion && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="w-full bg-secondary text-white text-center py-1"
          >
            {activePromotion.content}
          </motion.div>
        )}
      </AnimatePresence>
      <Outlet />

      <div className="mt-auto"></div>
      <footer className="bg-primary px-32 xl:px-28 lg:px-20 flex md:px-10 sm:px-6 md:flex-col sm:flex-col gap-y-8 justify-between text-white py-20 mt-24">
        <div className="w-1/3 flex flex-col gap-y-2 md:w-full sm:w-full">
          <div className="text-3xl font-extrabold">STOP DURERE</div>
          <p className="font-medium leading-5">
            stopdurere.md este o platformă care se ocupă cu reabilitarea
            persoanelor ce suferă de anumite dureri, prin terapie, folosind
            modalități moderne în procesul de tratare.
          </p>
          <div className="flex gap-x-4 text-4xl mt-4">
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiOutlineFacebook />
            </motion.a>
          </div>
        </div>
        <div className="text-right w-1/3 flex flex-col gap-y-2 md:w-full sm:w-full sm:text-left md:text-left">
          <div className="text-3xl font-extrabold">Contacte</div>
          <p className="font-medium leading-5">Telefon: 068504628</p>
          <p className="font-medium leading-5">
            Adresa de email: stopdureremd@gmail.com
          </p>
        </div>
      </footer>
      <div>
        <p className="text-center font-medium text-tertiary py-2">
          © {new Date().getFullYear()} StopDurere. Toate drepturile rezervate.
        </p>
      </div>
    </div>
  );
};

export default UserLayout;
