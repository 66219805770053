// AppointmentList.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url } from "../constants";

function AppointmentList() {
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchAppointments = async () => {
    try {
      const code = localStorage.getItem("code");
      const res = await axios.get(`${api_url}/appointments/`, {
        headers: { Authorization: `Bearer ${code}` },
      });
      setAppointments(res.data);
    } catch (error) {
      setError(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${api_url}/appointments/${id}/`);
      fetchAppointments(); // reîncarcă lista după ștergere
    } catch (error) {
      setError(error.response?.data?.error || error.message);
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto p-6 text-gray-700">
        Se încarcă programările...
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 mt-4">
      <h1 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4">
        Programări
      </h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {appointments.length === 0 ? (
        <div>Nu există programări.</div>
      ) : (
        <>
          {/* List view pentru ecrane mici și medii */}
          <div className="block lg:hidden">
            {appointments.map((appt) => (
              <div
                key={appt.id}
                className="border border-gray-200 p-4 mb-4 rounded shadow"
              >
                <h2 className="font-bold text-lg">{appt.name}</h2>
                <ul className="mt-2 ml-4 text-sm">
                  <li>
                    <span className="font-medium">Telefon:</span> {appt.phone}
                  </li>
                  <li>
                    <span className="font-medium">Serviciu:</span>{" "}
                    {appt.service}
                  </li>
                  <li>
                    <span className="font-medium">Data:</span>{" "}
                    {new Date(appt.created_at).toLocaleString()}
                  </li>
                </ul>
                <button
                  onClick={() => handleDelete(appt.id)}
                  className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded mt-2"
                >
                  Șterge
                </button>
              </div>
            ))}
          </div>
          {/* Tabel view pentru ecrane mari */}
          <div className="hidden lg:block overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">Nume</th>
                  <th className="py-2 px-4 border-b">Telefon</th>
                  <th className="py-2 px-4 border-b">Serviciu</th>
                  <th className="py-2 px-4 border-b">Data</th>
                  <th className="py-2 px-4 border-b">Acțiuni</th>
                </tr>
              </thead>
              <tbody>
                {appointments.map((appt) => (
                  <tr key={appt.id}>
                    <td className="py-2 px-4 border-b">{appt.name}</td>
                    <td className="py-2 px-4 border-b">{appt.phone}</td>
                    <td className="py-2 px-4 border-b">{appt.service}</td>
                    <td className="py-2 px-4 border-b">
                      {new Date(appt.created_at).toLocaleString()}
                    </td>
                    <td className="py-2 px-4 border-b">
                      <button
                        onClick={() => handleDelete(appt.id)}
                        className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded"
                      >
                        Șterge
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default AppointmentList;
