import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { motion } from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";
import axios from "axios";
import { api_url } from "../constants";
import { MdExitToApp } from "react-icons/md";
import { FaArchive } from "react-icons/fa";
import { HiDocumentPlus } from "react-icons/hi2";
import { BiCategory } from "react-icons/bi";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { RiDiscountPercentFill } from "react-icons/ri";

const AdminLayout = () => {
  const isSM = useMediaQuery("(max-width: 640px)");
  const isMD = useMediaQuery("(max-width: 1023px)");
  const location = useLocation();
  const navigate = useNavigate();
  const pageName = useSelector((state) => state.adminPage);
  const [accessGranted, setAccessGranted] = useState(false);
  // Stare pentru animația de loading la logare și la preluarea codului din localStorage
  const [loginLoading, setLoginLoading] = useState(false);
  const [localLoading, setLocalLoading] = useState(true);

  useEffect(() => {
    if (location.pathname === "/admin") {
      // navigate("/admin/services");
    }
    document.title = "Admin Panel";
  }, [location.pathname]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Funcție folosită în cazul în care logarea se face prin formular
  function fetchAccess(code) {
    setLoginLoading(true);
    axios
      .post(`${api_url}/verify/`, { code })
      .then((res) => {
        setAccessGranted(true);
        localStorage.setItem("code", code);
      })
      .catch((e) => {
        setAccessGranted(false);
        localStorage.removeItem("code");
      })
      .finally(() => {
        setLoginLoading(false);
      });
  }

  // Funcție folosită la preluarea codului din localStorage
  const fetchAccessLocal = (code) => {
    setLoginLoading(true);
    axios
      .post(`${api_url}/verify/`, { code })
      .then((res) => {
        setAccessGranted(true);
        localStorage.setItem("code", code);
      })
      .catch((e) => {
        setAccessGranted(false);
        localStorage.removeItem("code");
      })
      .finally(() => {
        setLoginLoading(false);
        setLocalLoading(false);
      });
  };

  // La montare, se caută codul în localStorage
  useEffect(() => {
    const code = localStorage.getItem("code");
    if (code) {
      fetchAccessLocal(code);
    } else {
      setLocalLoading(false);
    }
  }, []);

  // Dacă se preia codul din localStorage, se afișează un loading full screen
  if (localLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ loop: Infinity, duration: 1 }}
          className="w-16 h-16 border-4 border-dashed rounded-full border-white"
        ></motion.div>
      </div>
    );
  }

  return !accessGranted ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        fetchAccess(e.target.code.value);
      }}
      className="flex flex-col w-[20rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
    >
      <input
        type="text"
        placeholder="Cod de acces"
        className="admin-input"
        name="code"
      />
      <button
        className="admin-button py-1 px-4 mt-4 font-semibold"
        disabled={loginLoading}
      >
        {loginLoading ? (
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ loop: Infinity, duration: 1 }}
            className="w-6 h-6 border-4 border-dashed rounded-full border-white mx-auto"
          ></motion.div>
        ) : (
          "Submit access"
        )}
      </button>
    </form>
  ) : (
    <div className="flex">
      <div
        className={
          "w-56 md:w-64 relative sm:fixed sm:w-full py-8 min-h-[100vh] bg-secondary transition-transform ease-in-out duration-300 md:fixed z-50 " +
          (isMenuOpen
            ? "md:translate-x-0 sm:translate-x-0"
            : "md:-translate-x-full sm:-translate-x-full")
        }
      >
        {isMD && (
          <GiHamburgerMenu
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-2xl cursor-pointer block ml-4 text-white"
          />
        )}
        <ul className="text-white flex flex-col mt-6 text-lg">
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"posts"}
            className={
              "w-fit py-2 px-4 flex items-center gap-x-2" +
              (pageName === "posts" ? " font-semibold" : " font-medium")
            }
          >
            <FaArchive className="text-2xl" />
            Toate postarile
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"add-post"}
            className={
              "w-fit py-2 px-4 flex items-center gap-x-2" +
              (pageName === "add-post" ? " font-semibold" : " font-medium")
            }
          >
            <HiDocumentPlus className="text-2xl" />
            Adaugă postare
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"categories"}
            className={
              "w-fit py-2 px-4 flex items-center gap-x-2" +
              (pageName === "categories" ? " font-semibold" : " font-medium")
            }
          >
            <BiCategory className="text-2xl" />
            Categorii
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"appointments"}
            className={
              "w-fit py-2 px-4 flex items-center gap-x-2" +
              (pageName === "appointments" ? " font-semibold" : " font-medium")
            }
          >
            <RiCalendarScheduleLine className="text-2xl" />
            Programări
          </Link>
          <Link
            onClick={() => isMD && setIsMenuOpen(false)}
            to={"promotion"}
            className={
              "w-fit py-2 px-4 flex items-center gap-x-2" +
              (pageName === "promotion" ? " font-semibold" : " font-medium")
            }
          >
            <RiDiscountPercentFill className="text-2xl" />
            Setează promoție
          </Link>
        </ul>
      </div>
      <div className="w-[calc(100%-12rem)] md:w-full sm:w-full relative">
        <div className="w-full h-10 bg-primary flex items-center px-6 text-white relative uppercase font-semibold text-lg">
          {isMD && (
            <GiHamburgerMenu
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-2xl cursor-pointer text-white block mr-4"
            />
          )}
          {pageName}
          <MdExitToApp
            onClick={() => {
              localStorage.removeItem("code");
              setAccessGranted(false);
            }}
            className="text-2xl absolute top-1/2 right-6 -translate-y-1/2 cursor-pointer"
          />
        </div>
        <Outlet />
        {isMenuOpen && isMD && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={() => setIsMenuOpen(false)}
            className="fixed bottom-0 top-0 left-0 right-0 bg-black/20 z-10"
          ></motion.div>
        )}
      </div>
    </div>
  );
};

export default AdminLayout;
