import React, { useEffect, useState } from "react";
import logo from "../static/images/logo.svg";
import person from "../static/images/person.png";
import pdtr1 from "../static/images/pdtr1.jpg";
import pdtr2 from "../static/images/pdtr2.jpg";
import pdtr3 from "../static/images/pdtr3.jpg";
import pdtr4 from "../static/images/pdtr4.jpg";
import method1 from "../static/images/method1.jpg";
import method2 from "../static/images/method2.jpg";
import method3 from "../static/images/method3.jpg";
import method4 from "../static/images/method4.jpg";
import method5 from "../static/images/method5.jpg";
import contact from "../static/images/contact.jpg";
import { motion } from "framer-motion";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";
import { Typewriter } from "react-simple-typewriter";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { api_url } from "../constants";
import { FaRegCircleCheck } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { media_url } from "../constants";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const pdtrs = [
  {
    image: pdtr4,
    description:
      "Sechele si urmari ale traumelor, facturilor, dislocarilor, sindromul durerii posttraumatice, afectiuni ale ligamentelor si tendoanelor",
  },
  {
    image: pdtr2,
    description:
      "Afectiuni ale coloanei vertebrale si articulatii: ostehondroza, osteoartroza, scolioza, cifoza, spondiloza,  epicondelita, picior plat",
  },
  {
    image: pdtr3,
    description:
      "Afectiuni neurologice: migrene, dureri de cap, nevralgii intercostale, distanta neuro-vegetativa, epilepsie",
  },
  {
    image: pdtr1,
    description:
      "Afectiuni ale organelor interne: colecistita, gastrita, ulcer stomacal si duodenal, pancreatita, constipatie",
  },
];

const questions = [
  {
    question: "Cat dureaza si cum se desfasoara o sedinta?",
    answer:
      "Durata unei sedinte de terapie este variabila, in functie de procedura executata de terapeut - intre 30 de minute si o ora. Fiecare sedinta de tratament este personalizata, in functie de afectiune si reactia pacientului.",
  },
  {
    question: "Ce trebuie sa faca pacientul in timpul sedintei sau dupa?",
    answer:
      "in primul rind sa dezactivati soneria telefonului si sa va ginditi numai la sanatatea dumneavoastra! Toate sedintele se vor desfasura in liniste, iar intre seturile de miscari pe care terapeutul le va aplica veti ramine sa va odihniti, singur in camera.",
  },
  {
    question:
      "Dupa ce mi-am rezolvat problemele, cind trebuie sa mai revin la terapie?",
    answer:
      "sedintele de terapie au o buna remanenta a efectelor in timp, deci nu trebuie sa traiti cu teama ca daca nu mai mergi la terapie, o sa va revina simptomele de care tocmai ati scapat. Ceea ce nu inseamna, ca puteti reveni la greselile anterioare, iar corpul dumneavoastra o sa ramana sanatos.",
  },
  {
    question:
      "Cum si unde va pot contacta cei care vor sa beneficieze de terapie?",
    answer:
      "Puteti face o programare la numarul 068504628, sau sa scrieti un mesaj la adresa electronica stopdureremd@gmail.com",
  },
];

const Home = () => {
  const [homePosts, setHomePosts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);
  useEffect(() => {
    axios
      .get(`${api_url}/posts/?page=1`)
      .then((response) => {
        setHomePosts(response.data.posts);
      })
      .catch((error) =>
        console.error("Eroare la preluarea postărilor:", error)
      );
  }, []);

  //check for the width to show less in visibleCount
  useEffect(() => {
    const updateVisibleCount = () => {
      const width = window.innerWidth;
      if (width < 1024 && width > 640) {
        setVisibleCount(2);
      } else if (width < 640) {
        setVisibleCount(1);
      } else {
        setVisibleCount(3);
      }
    };

    updateVisibleCount(); // Setați inițial valoarea corectă

    window.addEventListener("resize", updateVisibleCount);

    return () => {
      window.removeEventListener("resize", updateVisibleCount);
    };
  }, []);

  const location = useLocation();

  useEffect(() => {
    const handleScrollToSection = () => {
      if (location.hash === "#methods") {
        const methodsSection = document.getElementById("methods");
        if (methodsSection) {
          methodsSection.scrollIntoView({ behavior: "smooth" });
        }
      }

      if (location.hash === "#reviews") {
        const reviewsSection = document.getElementById("reviews");
        if (reviewsSection) {
          reviewsSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    handleScrollToSection();
  }, [location.hash]);

  const handleLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleRight = () => {
    if (currentIndex < homePosts.length - visibleCount) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const [selectedQuestion, setSelectedQuestion] = useState(questions[0]);
  const [isAnswerChanging, setIsAnswerChanging] = useState(false);

  useEffect(() => {
    if (isAnswerChanging) {
      setTimeout(() => {
        setIsAnswerChanging(false);
      }, 200);
    }
  }, [isAnswerChanging]);

  const methods = [
    {
      title: "Kinesiologia aplicata",
      image: method1,
      description: [
        "Kinesiologia aplicata, terapia punctelor trigger si FDM sunt terapii manuale de tratare ale afectiunilor scheleto-musculare,  a coloanei vertebrale si ale articulatiilor. ",
        "Totodata kinesiologia aplicata e foarte eficienta in inlaturarea durerilor de cap, migrenelor, problemelor organelor interne,  stresului indelungat si starii de incordare permanenta. Se poate aplica  tuturor.",
      ],
    },
    {
      title: "Terapia Bowen",
      image: method2,
      description: [
        "Terapia Bowen este o alta terapie holistica, non-invaziva,  nedureroasa , ce se aplica manual pe anumite puncte la nivelul diverselor zone ale corpului.Terapia este utilizata pentru ameliorarea durerilor si se adreseaza tuturor categoriilor de varsta.",
        "Este eficienta in echilibrarea starilor psiho-emotionale, normalizeaza circuitele energiei, inlatura tensiunile musculare. Este recunoscuta pentru eficienta sa in tratarea afectiunilor interne si reducerea duratei convalescentei.",
      ],
    },
    {
      title: "Consultatie nutritionala",
      image: method3,
      description: [
        "Sanatatea depinde de mai multi factori, inclusiv dieta zilnica. O alimentatie echilibrata si variata poate preveni afectiuni precum obezitatea, diabetul, bolile de inima si cancerul. Consumul adecvat de vitamine si minerale intareste imunitatea, mentine sanatatea pielii si a organelor. ",
        "Nutritia este esentiala pentru dezvoltarea fizica si cognitiva, mai ales in copilarie si adolescenta. Consultatia nutritionala creeaza un plan alimentar personalizat, adaptat la sanatatea, greutatea si preferintele fiecarei persoane, oferind ghidare si sustinere pe durata programului.",
      ],
    },
    {
      title: "Terapia florala Bach",
      image: method4,
      description: [
        "Terapia florala Bach foloseste remedii din extracte de flori salbatice pentru tratarea problemelor emotionale si a durerii. Acestea restabilesc usor echilibrul dintre minte si corp, eliminand emotiile negative, cum ar fi frica, ingrijorarea, ura, indecizia,  care interfereaza cu echilibrul fiintei in ansamblu. ",
        "Remediile florale n-au efecte adverse si pot fi administrate si copiilor. Pentru  echilibrarea starii psiho-emotionale se prepara un amestec personalizat din mai multe remedii in dependenta de indicatii.",
      ],
    },
    {
      title: "Aromaterapia",
      image: method5,
      description: [
        "Aromaterapia este o terapie holistica ce contribuie la echilibrarea sanatatii fizice, mentale si spirituale folosind uleiuri esentiale. Acestea sunt substante concentrate, extrase din diverse parti ale plantelor (radacini, frunze, flori, scoarta) prin distilare cu aburi sau presare, in special pentru citrice. ",
        "Aromaterapia ajuta la ameliorarea unor afectiuni fizice precum durerile de cap, musculare, indigestia, reumatismul si problemele respiratorii (sinuzita, bronsita, astm). Totodata, are beneficii psihologice, reducand stresul, fricile, depresia, insomnia si lipsa de concentrare.",
      ],
    },
  ];

  const reviews = [
    {
      name: "Elena, 55 ani",
      review:
        "Aveam dureri in umarul si cotul stang, dificultati de respiratie si bufeuri suparatoare. Dupa prima sedinta, am simtit ameliorare, iar dupa 5 sedinte, toate simptomele au disparut.",
    },
    {
      name: "Eugen, 45 ani",
      review:
        "Acum un an ma dureau genunchii cand ma ridicam pe scari si coboram. Nici nu puteam sa merg mult. Trei sedinte au fost suficient ca sa uit de durerea de genunchi. Recomand tuturor!",
    },
    {
      name: "Vasile, 48 ani",
      review:
        "Aveam dureri de spate din cauza muncii in constructii. Operatia de hernie de disc nu a rezolvat problema; nu puteam sa ma aplec sa ma incalt. Dupa o sedinta la dna Elena, durerile au disparut si am putut sa ma incalt fara dificultate.",
    },
    {
      name: "Mihaela, 27 ani",
      review:
        "M-am adresat cu dureri dese si mari de cap, mai greu ma concentram si foarte repede ma oboseam. In copilarie am avut mai multe traume la cap.  Sunt multumita de tratamentul profesionist acordat. Recomand cu incredere!",
    },
  ];

  const services = [
    "Terapia Bowen",
    "Kinesiologia aplicata",
    "Terapia florala Bach",
    "Aromaterapia",
    "Consultatia nutritionala",
    "P-DTR",
  ];
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isAppointmentSuccess, setIsAppointmentSuccess] = useState(false);
  const [isAppointmentSending, setIsAppointmentSending] = useState(false);
  function MakeAppointment(e) {
    e.preventDefault();
    const name = e.target.name.value;
    const phone = e.target.phone.value;

    if (name.length == 0 || phone.length == 0) {
      alert("Va rugam sa completati toate campurile.");
      return;
    }

    const data = {
      name,
      phone,
      service: selectedService,
    };
    try {
      if (e.target.email.value) {
        data.email = e.target.email.value;
        delete data.service;
      }
    } catch (e) {}
    setIsAppointmentSending(true);
    axios
      .post(`${api_url}/appointment/`, data)
      .then((res) => {
        setIsAppointmentSuccess(true);
        setIsAppointmentSending(false);
      })
      .catch((err) => {
        alert("A aparut o eroare. Va rugam sa incercati din nou.");
        setIsAppointmentSending(false);
      });
  }

  const [selectedService, setSelectedService] = useState();
  const stripHtmlTags = (html) => {
    if (!html) return "";
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const getExcerpt = (htmlContent, limit = 250) => {
    const text = stripHtmlTags(htmlContent);
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };

  return (
    <div className="">
      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: isMenuOpen ? 0 : "-100%" }}
        transition={{ duration: 0.3 }}
        className="hidden pb-20 fixed sm:flex flex-col items-center justify-center gap-y-6 bg-background left-0 bottom-0 w-full h-screen z-50"
      >
        <button
          className="absolute top-5 right-5"
          onClick={() => setIsMenuOpen(false)}
        >
          <IoMdClose className="text-2xl" />
        </button>
        <div className="flex items-center gap-x-2 ">
          <img src={logo} alt="logo" className="h-20 w-20" />
          <div className="font-extrabold text-3xl leading-9">
            STOP
            <br />
            DURERE
          </div>
        </div>
        <div className="flex font-bold flex-col gap-y-8 mt-20 text-center text-2xl gap-x-10 justify-between">
          <a onClick={() => setIsMenuOpen(false)} href="#methods">
            Metodele noastre
          </a>
          <a onClick={() => setIsMenuOpen(false)} href="#faqs">
            Interbari frecvente
          </a>
          <a onClick={() => setIsMenuOpen(false)} href="#reviews">
            Marturii de la clienti
          </a>
        </div>
        <a onClick={() => setIsMenuOpen(false)} href="#appointment">
          <motion.button
            whileHover={{ scale: 1.025 }}
            whileTap={{ scale: 0.975 }}
            className="bg-primary text-white text-lg rounded-xl font-black px-10 py-3"
          >
            PROGRAMEAZA-TE
          </motion.button>
        </a>
      </motion.div>
      <div className="flex mt-24 md:mt-8 gap-y-12 sm:mt-12 gap-x-8 md:flex-col sm:flex-col pr-8 sm:pr-0 md:pr-0">
        <div className="pl-32 mt-12 xl:pl-28 lg:pl-20 md:w-full md:px-10 w-[60%] sm:w-full sm:px-6">
          <h1 className="text-tertiary sm:text-3xl md:text-center sm:text-center text-[3.25rem] lg:text-4xl md:text-5xl md:leading-[3.5rem] lg:leading-[3.5rem] leading-[4rem] font-extrabold">
            AICI SI ACUM POTI SCAPA <br className="md:hidden sm:hidden" />
            DE DURERE PRIN <br />
            <span className="text-secondary text-5xl">
              <Typewriter
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
                cursor={true}
                loop={true}
                words={[
                  "P-DTR",
                  "TERAPIA BOWEN",
                  "KINESIOLOGIA APLICATA",
                  "CONSULTATIA NUTRITIONALA",
                  "TERAPIA FLORALA BACH",
                  "AROMATERAPIA",
                ]}
              />
            </span>
          </h1>
          <p className="font-medium sm:text-base md:text-base text-lg md:text-center sm:text-center mt-2 pr-16 md:pr-0 sm:pr-0">
            Metodele noastre prezinta unele dintre cele mai profunde concepte
            terapeutice existente in lume in prezent.
            <span className="sm:hidden">
              {" "}
              Pot fi aplicate pentru orice dezechilibru in organism.
            </span>
          </p>
          <div className="flex gap-x-6 text-5xl mt-4 text-secondary md:hidden sm:hidden">
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              href="https://www.instagram.com/"
              target="_blank"
            >
              <FaInstagram className="" />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              href="https://www.facebook.com/"
              target="_blank"
            >
              <AiOutlineFacebook className="" />
            </motion.a>
          </div>
        </div>
        <div className="w-[40%] md:w-full relative sm:w-full flex justify-center items-center">
          <div className="w-full sm:h-[30rem] h-[35rem] rounded-t-full bg-primary/20"></div>

          {/* Formularul fără fundal, organizat în coloană */}
          <div
            className={
              "absolute  left-1/2 -translate-x-1/2 p-0 flex flex-col w-full max-w-md " +
              (isAppointmentSuccess
                ? "top-1/2 -translate-y-1/2"
                : "top-0 translate-y-1/3")
            }
          >
            {isAppointmentSuccess ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="text-center flex flex-col text-black gap-y-4 w-full px-8"
              >
                <FaRegCircleCheck className="text-7xl mx-auto " />
                <p className=" text-lg font-medium">
                  Mulțumim pentru programare! Vei fi contactat în cel mai scurt
                  timp posibil.
                </p>
              </motion.div>
            ) : (
              <form
                onSubmit={MakeAppointment}
                className="flex flex-col gap-y-5 sm:px-10"
              >
                <motion.input
                  initial={{ x: -50, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.2,
                    type: "tween",
                    ease: "easeInOut",
                  }}
                  name="phone"
                  placeholder="Număr de telefon"
                  type="text"
                  disabled={isAppointmentSending}
                  className="bg-white py-4 px-4 rounded-lg sm:text-base outline-none w-full font-medium text-lg placeholder:italic placeholder:font-medium"
                />
                <motion.input
                  disabled={isAppointmentSending}
                  initial={{ x: -50, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.2,
                    type: "tween",
                    ease: "easeInOut",
                    delay: 0.2,
                  }}
                  name="name"
                  placeholder="Nume complet"
                  type="text"
                  className="bg-white py-4 px-4 rounded-lg sm:text-base outline-none w-full font-medium text-lg placeholder:italic placeholder:font-medium"
                />
                <motion.input
                  disabled={isAppointmentSending}
                  initial={{ x: -50, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.2,
                    type: "tween",
                    ease: "easeInOut",
                    delay: 0.4,
                  }}
                  name="email"
                  placeholder="Email (optional)"
                  type="text"
                  className="bg-white py-4 px-4 rounded-lg sm:text-base outline-none w-full font-medium text-lg placeholder:italic placeholder:font-medium"
                />
                {/* <motion.select
                  disabled={isAppointmentSending}
                  initial={{ x: -50, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.2,
                    type: "tween",
                    ease: "easeInOut",
                    delay: 0.4,
                  }}
                  name="service"
                  value={selectedService}
                  onChange={(e) => setSelectedService(e.target.value)}
                  className="bg-white py-4 px-4 rounded-lg italic  sm:text-base text-black/50 outline-none w-full font-medium text-lg placeholder:italic placeholder:font-medium"
                >
                  <option disabled selected>
                    Serviciul Solicitat
                  </option>
                  {services.map((service) => (
                    <option key={service} value={service}>
                      {service}
                    </option>
                  ))}
                  <option value="P-dtr">P-DTR</option>
                </motion.select> */}
                <motion.button
                  disabled={isAppointmentSending}
                  initial={{ x: -50, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.4,
                    type: "tween",
                    ease: "easeInOut",
                    delay: 0.7,
                  }}
                  className="bg-primary text-white text-lg sm:text-base w-full rounded-xl font-black px-10 py-3 block"
                >
                  PROGRAMEAZĂ-TE
                </motion.button>
                <p className="text-lg mt-4 font-medium mb-2">
                  sau sună la{" "}
                  <a href="tel:068504628" className=" font-black">
                    <Typewriter words={["068504628"]} />
                  </a>
                </p>
              </form>
            )}
          </div>
        </div>
      </div>
      <hr className="h-1 w-full bg-primary" />
      {/* Secțiunea pentru ultimele postări */}
      {homePosts.length > 0 && (
        <section className="mt-12 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold mb-1">Ultimele postari</h2>
            <Link
              to="/posts"
              className="text-primary font-bold hover:underline"
            >
              Vezi toate postările
            </Link>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="relative"
          >
            {/* Săgeata stânga */}
            <button
              onClick={handleLeft}
              disabled={currentIndex === 0}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-gray-200 rounded-full disabled:opacity-50"
            >
              <FaArrowLeft />
            </button>

            <div className="overflow-hidden">
              <div
                className="flex gap-x-6 transition-transform duration-300"
                style={{
                  transform: `translateX(-${
                    currentIndex * (100 / visibleCount)
                  }%)`,
                }}
              >
                {homePosts.map((post) => {
                  const postText = post.excerpt || post.content || "";
                  return (
                    <div
                      key={post.id}
                      className="flex-shrink-0 p-2"
                      style={{ width: `calc(100% / ${visibleCount})` }}
                    >
                      <Link
                        to={`/posts/${post.slug}`}
                        className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 h-full flex flex-col"
                      >
                        {post.cover_image && (
                          <img
                            src={media_url + post.cover_image}
                            alt={post.title}
                            className="w-full h-56 object-cover rounded-t-lg"
                          />
                        )}
                        <div className="p-4 flex-grow">
                          <h3 className="text-base font-semibold mb-2">
                            {post.title}
                          </h3>
                          {postText && (
                            <p className="text-sm text-gray-600">
                              {getExcerpt(postText)}
                            </p>
                          )}
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Săgeata dreapta */}
            <button
              onClick={handleRight}
              disabled={currentIndex >= homePosts.length - visibleCount}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-gray-200 rounded-full disabled:opacity-50"
            >
              <FaArrowRight />
            </button>
          </motion.div>
        </section>
      )}

      <section className="px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <div className="mt-16 flex justify-between items-center flex-wrap gap-x-10 gap-y-3">
          <h2 className="">Ce se poate de tratat prin intermediul P-dtr?</h2>
          <a href="#appointment" className="sm:w-full">
            <motion.button
              whileHover={{ scale: 1.025 }}
              whileTap={{ scale: 0.975 }}
              onClick={() => setSelectedService("P-DTR")}
              className="bg-primary sm:w-full block text-white uppercase text-lg rounded-xl font-black px-10 py-3"
            >
              SUNT INTERESAT DE P-DTR
            </motion.button>
          </a>
        </div>
        <p className="w-[60%] lg:w-4/5 subtitle md:w-full sm:w-full mt-2">
          P-DTR este o metoda foarte blanda, neinvaziva si nedureroasa, bazata
          pe efectul manual in timp real al anumitor receptori senzoriali asupra
          corpului uman.
        </p>
        <div className="grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1 md:gap-y-8 sm:gap-y-8 gap-x-20 xl:gap-x-12 lg:gap-x-8 xl:gap-y-8 lg:gap-y-6 gap-y-12 mt-6">
          {pdtrs.map((pdtr, index) => (
            <motion.div
              initial={{ x: index % 2 == 0 ? -100 : 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                duration: 0.3,
                delay: index * 0.1,
                type: "tween",
                ease: "easeInOut",
              }}
              viewport={{ once: true }}
              className="bg-primary rounded-2xl relative"
            >
              <img
                src={pdtr.image}
                alt="pdtr"
                className="w-full h-72 object-cover rounded-t-2xl object-bottom"
              />
              <p className="text-white text-lg font-medium p-4 md:text-base lg:text-base sm:text-base">
                {pdtr.description}
              </p>
            </motion.div>
          ))}
        </div>
      </section>

      <section
        id="methods"
        className="px-32 mt-24 xl:px-28 lg:px-20 md:px-10 sm:px-6"
      >
        <h2>Afla mai multe despre metodele noastre</h2>
        <p className="w-[60%] subtitle lg:w-4/5 sm:w-full md:w-full mt-2">
          Toate aceste terapii sunt terapii complementare medicinei alopate si
          ele nu pot substitui prescriptiile medicale.
        </p>
        <div className="flex flex-col gap-y-12 mt-6">
          {methods.map((method, index) => (
            <div
              className={
                " flex lg:flex-col sm:flex-col-reverse gap-y-6 md:flex-col rounded-2xl relative gap-x-4 " +
                (index % 2 == 0 ? "flex-row-reverse	" : "")
              }
            >
              <div className="flex text-left flex-col gap-y-6">
                {method.description.map((description) => (
                  <p className=" text-lg font-medium">{description}</p>
                ))}

                <a href="#appointment">
                  <motion.button
                    whileHover={{ scale: 1.025 }}
                    whileTap={{ scale: 0.975 }}
                    onClick={() => setSelectedService(method.title)}
                    className="bg-primary text-white uppercase text-lg rounded-xl font-black px-10 py-3 block"
                  >
                    SUNT INTERESAT DE {method.title}
                  </motion.button>
                </a>
              </div>
              <img
                src={method.image}
                alt="method"
                className="w-1/3 h-80 object-cover rounded-xl object-bottom md:w-full sm:w-full border-2 border-solid border-primary"
              />
            </div>
          ))}
        </div>
      </section>
      <section className="px-32 mt-20 xl:px-28 lg:px-20 md:px-10 sm:px-6 ">
        <a id="appointment"></a>
        <h2>Programeaza-te acum si scapa de probleme</h2>
        <p className="w-[60%] subtitle lg:w-4/5 md:w-full sm:w-full mt-2">
          Odata completat formularul, vei fi contactat pentru a stabili un
          program convenabil pentru tine.
        </p>
        <div className="relative w-full">
          <img
            src={contact}
            alt="contact"
            className="w-full object-cover h-96 sm:h-[30rem] rounded-2xl mt-6"
          />
          <div className="absolute left-0 top-0 w-full h-full bg-primary/15"></div>
          <motion.div
            initial={{ width: "10%" }}
            whileInView={{ width: isAppointmentSuccess ? "100%" : "66.66%" }}
            viewport={{ once: true }}
            transition={{
              duration: 1.5,
              type: "tween",
              ease: "easeInOut",
            }}
            className="absolute left-0 top-0 from-40% h-full from-[#67C3AA]/80 to-transparent bg-gradient-to-r"
          ></motion.div>
          {isAppointmentSuccess ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.3,
              }}
              className="absolute-center text-center flex flex-col gap-y-4 w-full px-8"
            >
              <FaRegCircleCheck className="text-7xl mx-auto text-white" />
              <p className="text-white text-lg font-medium">
                Multumim pentru programare! Veti fi contactat in cel mai scurt
                timp posibil.
              </p>
            </motion.div>
          ) : (
            <form
              onSubmit={MakeAppointment}
              className="grid grid-cols-2 sm:grid-cols-1 gap-x-6 gap-y-6 sm:px-4 sm:w-full absolute left-10 sm:left-0 top-14"
            >
              <motion.input
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.4,
                  type: "tween",
                  ease: "easeInOut",
                  delay: 0.3,
                }}
                name="phone"
                placeholder="Numar de telefon"
                type="text"
                disabled={isAppointmentSending}
                className="bg-white py-4 px-4 rounded-lg outline-none w-96 sm:w-full font-medium text-lg placeholder:italic placeholder:font-medium"
              />
              <motion.input
                disabled={isAppointmentSending}
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.4,
                  type: "tween",
                  ease: "easeInOut",
                  delay: 0.5,
                }}
                name="name"
                placeholder="Nume complet"
                type="text"
                className="bg-white py-4 px-4 rounded-lg outline-none w-96 sm:w-full font-medium text-lg placeholder:italic placeholder:font-medium"
              />
              <motion.select
                disabled={isAppointmentSending}
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.4,
                  type: "tween",
                  ease: "easeInOut",
                  delay: 0.5,
                }}
                name="service"
                value={selectedService}
                onChange={(e) => setSelectedService(e.target.value)}
                className="bg-white py-4 px-4 rounded-lg italic text-black/50 outline-none w-96 sm:w-full font-medium text-lg placeholder:italic placeholder:font-medium"
              >
                <option className="" disabled selected>
                  Serviciul Solicitat
                </option>
                {services.map((service) => (
                  <option value={service}>{service}</option>
                ))}
              </motion.select>
              <motion.button
                disabled={isAppointmentSending}
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.4,
                  type: "tween",
                  ease: "easeInOut",
                  delay: 0.7,
                }}
                className="bg-primary text-white text-lg w-96 sm:w-full rounded-xl font-black px-10 py-3 block"
              >
                PROGRAMEAZA-TE
              </motion.button>
            </form>
          )}
        </div>
      </section>
      <section
        id="reviews"
        className="px-32 xl:px-28 lg:px-20 mt-32 pt-10 pb-28 relative md:px-10 sm:px-6"
      >
        <div className="bg-primary py-40 z-0 absolute top-0 left-0 w-full"></div>
        <h2 className="text-white z-10 relative">
          Iata ce spun clientii nostri
        </h2>
        <div className="grid grid-cols-3 z-10 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-y-6 bottom-0 gap-x-12 left-0 mt-6">
          {reviews.map((review, index) => (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.2,
                type: "tween",
                ease: "easeInOut",
                delay: index * 0.1,
              }}
              viewport={{ once: true }}
              className={
                "bg-white rounded-2xl p-6 drop-shadow-lg " +
                (index == reviews.length - 1 ? "hidden lg:block" : "")
              }
            >
              <p className="text-lg font-medium italic">"{review.review}"</p>
              <p className="text-lg font-bold mt-4">{review.name}</p>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
