import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url, media_url } from "../constants";
import { useParams, Link } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { Helmet } from "react-helmet-async";

const LoadingSpinner = () => (
  <div className="flex flex-col items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-primary"></div>
    <p className="mt-4 text-gray-700 text-lg">Se încarcă...</p>
  </div>
);

function PostDetail() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [suggestedPosts, setSuggestedPosts] = useState([]);
  const [error, setError] = useState("");
  const [highResLoaded, setHighResLoaded] = useState(false);

  useEffect(() => {
    // Preluăm detaliile postării
    axios
      .get(`${api_url}/posts/${slug}`)
      .then((response) => setPost(response.data))
      .catch((error) => setError(error.response?.data?.error || error.message));

    // Preluăm postările sugerate pentru această postare
    axios
      .get(`${api_url}/posts/suggested/${slug}/`)
      .then((response) => setSuggestedPosts(response.data))
      .catch((error) =>
        console.error("Eroare la preluarea postărilor sugerate:", error)
      );
    //scroll to top
    window.scrollTo(0, 0);
  }, [slug]);

  if (error) {
    return <div className="w-full p-6 text-red-500">{error}</div>;
  }
  if (!post) {
    return <LoadingSpinner />;
  }

  return (
    <div className="w-full flex mt-4 flex-col lg:flex-row xl:flex-row 2xl:flex-row px-32 xl:px-28 lg:px-20 md:px-10 sm:px-0">
      {/* Coloana stângă: Conținutul principal */}

      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.meta_description} />
        <meta property="og:title" content={post.meta_title} />
        <meta property="og:description" content={post.meta_description} />
        <meta property="og:image" content={media_url + post.cover_image} />
        <meta name="keywords" content={post.meta_keywords} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="lg:w-3/4 w-full p-6">
        {post.cover_image && (
          <div className="relative">
            {post.cover_image_lowres && (
              <img
                src={media_url + post.cover_image_lowres}
                alt={post.title}
                className={`w-full rounded-lg mb-6 shadow-lg absolute top-0 left-0 transition-opacity duration-500 ${
                  highResLoaded ? "opacity-0" : "opacity-100"
                }`}
                style={{ filter: "blur(20px)" }}
              />
            )}
            <img
              src={media_url + post.cover_image}
              alt={post.title}
              className="w-full rounded-lg mb-6 shadow-lg transition-opacity duration-500"
              onLoad={() => setHighResLoaded(true)}
              style={{ opacity: highResLoaded ? 1 : 0 }}
            />
          </div>
        )}
        <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
        <div className="mb-4 text-gray-600">
          Publicat pe: {new Date(post.created_at).toLocaleDateString()}
        </div>
        <div
          className="ql-editor h-fit"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
        {post.categories && post.categories.length > 0 && (
          <div className="mt-6">
            <p className="text-gray-700">
              Categorii:{" "}
              {post.categories.map((cat, index) => (
                <span key={index} className="mr-2">
                  <Link
                    to={`/posts/category/${cat}`}
                    className="text-blue-600 hover:underline"
                  >
                    {cat}
                  </Link>
                </span>
              ))}
            </p>
          </div>
        )}
      </div>

      {/* Coloana dreaptă: Postări sugerate */}
      <div className="lg:w-1/4 xl:w-1/4 2xl:w-1/4 w-full p-6 border-t lg:border-t-0 lg:border-l xl:border-l 2xl:border-l xl:border-t-0 2xl:border-t-0 border-gray-200">
        <h2 className="text-2xl font-bold mb-4">Postări sugerate</h2>
        {suggestedPosts.length > 0 ? (
          <ul>
            {suggestedPosts.map((suggested) => (
              <li key={suggested.id} className="mb-4">
                <Link
                  to={`/posts/${suggested.slug}`}
                  className="flex items-center gap-4 hover:underline"
                >
                  {suggested.cover_image && (
                    <img
                      src={media_url + suggested.cover_image}
                      alt={suggested.title}
                      className="w-16 h-16 object-cover rounded"
                    />
                  )}
                  <span className="text-lg font-medium">{suggested.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>Nu există postări sugerate.</p>
        )}
      </div>
    </div>
  );
}

export default PostDetail;
