import React, { useState } from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import aromaterapyBachImage from "../static/images/aromaterapy-info.jpg";
import axios from "axios";
import { api_url } from "../constants";
const AromaterapieBachPage = () => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const phone = e.target.phone.value;

    if (name.length == 0 || phone.length == 0) {
      alert("Va rugam sa completati toate campurile.");
      return;
    }

    const data = {
      name,
      phone,
      service: "Aromaterapie & Terapia Bach",
    };
    if (e.target.email.value) {
      data.email = e.target.email.value;
    }
    setIsFormSubmitting(true);
    axios
      .post(`${api_url}/appointment/`, data)
      .then((res) => {
        setIsFormSuccess(true);
        setIsFormSubmitting(false);
      })
      .catch((err) => {
        alert("A aparut o eroare. Va rugam sa incercati din nou.");
        setIsFormSubmitting(false);
      });
  };

  return (
    <div>
      <Helmet>
        <title>
          Aromaterapie & Terapia Bach - Eliberează-te de stres și anxietate |
          Stop Durere
        </title>
        <meta
          name="description"
          content="Descoperă aromaterapia și terapia Bach pentru restabilirea echilibrului emoțional și fizic. Programează-te acum pentru o viață în armonie!"
        />
        <link
          rel="canonical"
          href="https://www.stopdurere.md/aromaterapie-bach"
        />
      </Helmet>

      {/* Secțiunea Hero */}
      <section className="bg-primary text-white py-20 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <h1 className="text-4xl md:text-4xl sm:text-3xl font-extrabold mb-4">
          Eliberează-te de stres și anxietate: Aromaterapie și Terapia Bach
          pentru echilibru emoțional!
        </h1>
        <h2 className="text-2xl md:text-2xl sm:text-xl font-bold mb-6">
          Terapeut certificat cu experiență în aromaterapie și terapia Bach,
          dedicat restabilirii echilibrului emoțional și fizic.
        </h2>
        <p className="text-lg font-bold md:text-base sm:text-sm mb-2">
          Te simți copleșit de stres, anxietate sau emoții negative? Descoperă
          puterea vindecătoare a aromaterapiei și terapiei Bach și redobândește
          armonia interioară!
        </p>{" "}
        <p className="text-lg mb-8 sm:text-base">
          Tot ce trebuie să faci este să lași datele de contact. Apoi, asistenta
          te va contacta să te înscrie la o programare.
        </p>
        <a href="#appointment">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-secondary text-white px-10 py-3 rounded-lg font-bold"
          >
            Programează-te acum
          </motion.button>
        </a>
      </section>

      {/* Secțiunea de descriere */}
      <section className="py-12 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <img
          src={aromaterapyBachImage}
          alt="Aromaterapie și Terapia Bach"
          className="w-full mb-6 rounded-xl drop-shadow-md"
        />
        <div className="mb-12">
          <p className="text-lg md:text-lg sm:text-base font-medium mb-4">
            Te simți tensionat și stresat constant? Emoțiile negative te
            copleșesc și îți afectează starea de bine? Ai probleme cu somnul,
            anxietate sau stări de tristețe inexplicabile? Te simți
            dezechilibrat și deconectat de tine însuți? Ai încercat diverse
            metode, dar simți că nu ai ajuns la rădăcina problemei? Te simți
            frustrat și neputincios, parcă ai fi captiv într-o stare emoțională
            negativă?
          </p>
          <p className="text-lg md:text-lg sm:text-base font-medium mb-4">
            Te simți ca și cum trăiești într-o lume haotică, care ai impresia că
            s-a întors cu picioarele în sus, lume în care emoțiile te copleșesc
            și te împiedică să te bucuri de viață? Simți că ai pierdut
            conexiunea cu tine însuți, că nu mai știi cine ești și ce-ți
            dorești?
          </p>{" "}
          <p className="text-lg md:text-lg sm:text-base font-medium mb-4">
            Îți amintești ultima oară când te-ai simțit calm, liniștit și în
            armonie cu tine însuți? Poate că a fost acum mult timp, înainte ca
            stresul, grijile și emoțiile negative să-ți fure echilibrul
            interior.
          </p>
          <p className="text-lg md:text-lg sm:text-base font-medium mb-4">
            Imaginează-ți acum că te trezești dimineața cu o stare de calm și
            liniște interioară. Imaginează-ți că poți gestiona stresul și
            emoțiile negative cu ușurință. Imaginează- ți că te simți conectat
            cu tine însuți, că te bucuri de fiecare moment al vieții.
          </p>
          <p className="text-lg md:text-lg sm:text-base font-medium mb-4">
            Aromaterapia și terapia Bach sunt terapii naturale, holistice,
            blânde și eficiente, care te ajută să-ți redobândești echilibrul
            emoțional și fizic. Uleiurile esențiale și remediile florale Bach
            acționează la nivel subtil, armonizând energiile și restabilind
            starea de bine. Aromaterapia este recunoscută pentru capacitatea sa
            de a induce relaxare și de a reduce stresul. Este apreciată pentru
            efectele sale benefice asupra corpului și minții, fiind utilizată
            atât în scop terapeutic, cât și pentru îmbunătățirea stării generale
            de bine. Uleiurile esențiale conțin compuși activi care pot
            influența sistemul nervos, imunitar și emoțional, oferind o gamă
            largă de beneficii.
          </p>
        </div>

        <div className="mb-12">
          <h3 className="text-2xl md:text-xl sm:text-lg font-bold mb-4">
            Beneficii:
          </h3>

          <ul className="list-disc list-inside mt-4 text-lg md:text-base sm:text-sm">
            <li>Reducerea stresului și anxietății.</li>
            <li>Îmbunătățirea calității somnului.</li>
            <li>Gestionarea emoțiilor negative.</li>
            <li>Restabilirea echilibrului emoțional și fizic.</li>
            <li>Conectarea cu sinele interior.</li>
            <li>Creșterea nivelului de energie și concentrare.</li>
            <li>Îmbunătățirea stării de spirit și a calității vieții.</li>
          </ul>
        </div>

        <div className="mb-8">
          <h3 className="text-2xl md:text-xl sm:text-lg font-bold mb-4">
            Bonusuri
          </h3>
          <ul className="list-disc list-inside mt-4 text-lg md:text-base sm:text-sm">
            <li>
              Ghid de utilizare a uleiurilor esențiale și remediilor florale
              Bach.
            </li>
            <li>
              Programează-te acum și vei primi 20% discount la pachetul de
              terapie!
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h3 className="text-2xl font-bold mb-4 lg:text-xl sm:text-lg">
            Acționează acum!
          </h3>
          <p className="text-lg mb-4 sm:text-base">
            Nu lăsa obiceiurile nesănătoase să-ți controleze viața. Completează
            formularul de mai jos și fă primul pas spre o viață plină de energie
            și vitalitate!
          </p>
        </div>
      </section>

      <a id="appointment"></a>

      {/* Formularul de programare */}
      <section className="bg-gray-100 py-20 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <h3 className="text-3xl md:text-2xl sm:text-xl font-bold text-center mb-8">
          Programează-te pentru Aromaterapie & Terapia Bach
        </h3>
        {isFormSuccess ? (
          <div className="text-center">
            <p className="text-xl md:text-lg sm:text-base font-medium text-green-600">
              Mulțumim pentru programare! Vei fi contactat în cel mai scurt timp
              posibil.
            </p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="max-w-lg mx-auto flex flex-col gap-y-6"
          >
            <input
              type="text"
              name="name"
              placeholder="Nume complet"
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Număr de telefon"
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
            <input
              type="text"
              name="email"
              placeholder="Email (opțional)"
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
            />
            <motion.button
              type="submit"
              disabled={isFormSubmitting}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-primary text-white text-xl md:text-lg sm:text-base rounded-lg font-bold px-10 py-3"
            >
              {isFormSubmitting ? "Se trimite..." : "Programează-te"}
            </motion.button>
          </form>
        )}
      </section>
    </div>
  );
};

export default AromaterapieBachPage;
