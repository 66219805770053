import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { motion } from "framer-motion";
import axios from "axios";
import { api_url } from "../constants";

// Înregistrează modulul de redimensionare a imaginilor pentru Quill
Quill.register("modules/imageResize", ImageResize);

const editorFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "color",
  "background",
  "align",
  "list",
  "bullet",
  "link",
  "image",
];

function generateSlug(str) {
  str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  return str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

function getPostStats(html) {
  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  const text = tmp.textContent || tmp.innerText || "";
  const wordCount = text.trim().split(/\s+/).filter(Boolean).length;
  const charCount = text.length;
  const images = tmp.querySelectorAll("img").length;
  const links = tmp.querySelectorAll("a").length;
  return { wordCount, charCount, images, links };
}

function AddPost() {
  // State pentru postare
  const [title, setTitle] = useState("");
  const [categories, setCategories] = useState([]);
  const [editorContent, setEditorContent] = useState("");

  // SEO
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [slug, setSlug] = useState("");

  // State pentru a reține ID-ul postării (modificare vs. creare)
  const [postId, setPostId] = useState(null);

  // Statistici
  const [postStats, setPostStats] = useState({
    wordCount: 0,
    charCount: 0,
    images: 0,
    links: 0,
  });

  // Mesaje
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  // Status postare (Draft sau Publicată)
  const [status, setStatus] = useState("Draft");
  const [postLink, setPostLink] = useState("");

  // Imagine de copertă
  const [coverImage, setCoverImage] = useState(null);
  const coverInputRef = useRef(null);

  // Crop imagine (pentru editor)
  const [showCropModal, setShowCropModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imageRef = useRef(null);
  const fileInputRef = useRef(null);

  // Quill și SEO Loading
  const quillRef = useRef(null);
  const [seoLoading, setSeoLoading] = useState(false);

  // State pentru a determina dacă postarea este Draft
  const [isDraft, setIsDraft] = useState(true);

  // Pentru încărcarea categoriilor disponibile
  const [availableCategories, setAvailableCategories] = useState([]);

  const fetchAvailableCategories = async () => {
    try {
      const response = await axios.get(api_url + "/get-categories");
      setAvailableCategories(response.data);
    } catch (error) {
      console.error("Eroare la preluarea categoriilor:", error);
    }
  };

  // HANDLERS

  const handleImageUpload = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const editorModules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
      ],
      handlers: {
        image: handleImageUpload, // handler-ul personalizat
      },
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        // Setează sursa imaginii și deschide modalul de crop
        setImageSrc(reader.result);
        setShowCropModal(true);
      };
      reader.readAsDataURL(file);
      e.target.value = "";
    }
  };

  const handleCoverUpload = () => {
    if (coverInputRef.current) {
      coverInputRef.current.click();
    }
  };

  const onCoverFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setCoverImage(reader.result);
      });
      reader.readAsDataURL(file);
      e.target.value = "";
    }
  };

  const onImageLoaded = useCallback((e) => {
    if (e.currentTarget) {
      imageRef.current = e.currentTarget;
    }
  }, []);

  const getCroppedImg = useCallback((imageEl, theCrop) => {
    if (!theCrop || !imageEl) return;
    const canvas = document.createElement("canvas");
    const scaleX = imageEl.naturalWidth / imageEl.width;
    const scaleY = imageEl.naturalHeight / imageEl.height;
    canvas.width = theCrop.width;
    canvas.height = theCrop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      imageEl,
      theCrop.x * scaleX,
      theCrop.y * scaleY,
      theCrop.width * scaleX,
      theCrop.height * scaleY,
      0,
      0,
      theCrop.width,
      theCrop.height
    );
    return canvas.toDataURL("image/jpeg");
  }, []);

  const insertCroppedImage = () => {
    if (imageRef.current && completedCrop) {
      const croppedImageUrl = getCroppedImg(imageRef.current, completedCrop);
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      editor.insertEmbed(range ? range.index : 0, "image", croppedImageUrl);
      // Închide modalul și resetează stările
      setShowCropModal(false);
      setImageSrc(null);
      setCrop({ aspect: 16 / 9 });
      setCompletedCrop(null);
    }
  };

  const handleGenerateSEO = async (e) => {
    e.preventDefault();
    setSeoLoading(true);
    try {
      // Elimină tag-urile <img> pentru a nu trimite datele mari (ex.: base64)
      const strippedContent = editorContent.replace(/<img[^>]*>/g, "");

      const response = await axios.post(api_url + "/generate-seo", {
        title,
        content: strippedContent,
        code: localStorage.getItem("code"),
      });
      const data = response.data;
      setMetaTitle(data.metaTitle || "");
      setMetaDescription(data.metaDescription || "");
      setMetaKeywords(data.metaKeywords || "");
    } catch (error) {
      console.error(error);
      setErrorMessage(
        "Eroare la generarea SEO. Verifică endpoint-ul API și asigură-te că Django returnează JSON."
      );
    }
    setSeoLoading(false);
  };

  // Salvează postarea (dacă postId există, se actualizează postarea)
  const savePost = async (draftStatus = true) => {
    setErrorMessage("");
    try {
      const editor = quillRef.current ? quillRef.current.getEditor() : null;
      const currentContent = editor ? editor.root.innerHTML : editorContent;

      // Construiește payload-ul, adăugând ID-ul postării dacă există
      const payload = {
        title,
        slug,
        content: currentContent,
        categories,
        metaTitle,
        metaDescription,
        metaKeywords,
        isDraft: draftStatus,
        coverImage,
        code: localStorage.getItem("code"),
      };
      if (postId) {
        payload.id = postId;
      }

      const response = await axios.post(api_url + "/save-post", payload);

      // Actualizează postId din răspuns (dacă este o primă creare sau actualizare)
      if (response.data.postId) {
        setPostId(response.data.postId);
      }

      setSuccessMessage(
        draftStatus
          ? "Postarea a fost salvată ca draft!"
          : "Postarea a fost creată/modificată cu succes!"
      );
      if (!draftStatus) {
        setStatus("Publicată");
        setPostLink(`${window.location.origin}/posts/${slug}`);
      }
    } catch (err) {
      setErrorMessage(err.response?.data?.error || err.message);
    }
  };

  const handlePublish = async () => {
    if (!title.trim()) {
      setErrorMessage("Titlul este obligatoriu pentru publicare.");
      return;
    }
    if (!editorContent.trim()) {
      setErrorMessage("Conținutul este obligatoriu pentru publicare.");
      return;
    }
    if (!slug.trim()) {
      setErrorMessage("Slug-ul este obligatoriu pentru publicare.");
      return;
    }
    setErrorMessage("");
    setIsDraft(false);
    await savePost(false);
    setSuccessMessage("Postarea a fost publicată cu succes!");
    setStatus("Publicată");
    setPostLink(`${window.location.origin}/posts/${slug}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await savePost(isDraft);
  };

  const togglePublication = async () => {
    const newStatus = status === "Publicată" ? "Draft" : "Publicată";
    setIsDraft(newStatus === "Draft");
    await savePost(newStatus === "Draft");
    setStatus(newStatus);
    setSuccessMessage(`Postarea a fost modificată. Noua stare: ${newStatus}`);
  };

  // Actualizează slug-ul: folosește metaTitle dacă există, altfel titlul
  useEffect(() => {
    if (metaTitle.trim()) {
      setSlug(generateSlug(metaTitle));
    } else {
      setSlug(generateSlug(title));
    }
  }, [title, metaTitle]);

  useEffect(() => {
    setPostStats(getPostStats(editorContent));
  }, [editorContent]);

  useEffect(() => {
    fetchAvailableCategories();
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const toolbar = editor.getModule("toolbar");
      if (toolbar) {
        toolbar.handlers.image = handleImageUpload;
      }
    }
  }, []);

  useEffect(() => {
    if (quillRef.current && editorContent) {
      const editor = quillRef.current.getEditor();
      const delta = editor.clipboard.convert(editorContent);
      editor.setContents(delta, "silent");
    }
  }, []);

  return (
    <div className="w-full min-h-screen bg-gray-100 p-6 sm:p-0 mt-4">
      {errorMessage && (
        <motion.div
          className="max-w-7xl mx-auto mb-4 bg-red-200 text-red-800 p-4 rounded"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {errorMessage}
        </motion.div>
      )}
      {successMessage && (
        <motion.div
          className="max-w-7xl mx-auto mb-4 bg-green-200 text-green-800 p-4 rounded"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {successMessage}
        </motion.div>
      )}

      <form onSubmit={handleSubmit} className="w-full mx-auto">
        <div className="flex flex-col lg:flex-row xl:flex-row 2xl:flex-row gap-6">
          <motion.div
            className="flex-1 bg-white shadow-md rounded-lg p-6"
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-2xl md:text-xl sm:text-xl font-bold mb-6">
              Creează/Editează Postare
            </h1>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">
                Titlu Postare
              </label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full p-2 border rounded"
                placeholder="Introduceți titlul postării"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">
                Conținut Postare
              </label>
              <div className="quill-container">
                <ReactQuill
                  ref={quillRef}
                  value={editorContent}
                  onChange={setEditorContent}
                  modules={editorModules}
                  formats={editorFormats}
                  placeholder="Scrie aici..."
                  style={{ fontSize: "1.5rem" }}
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={onFileChange}
                />
              </div>
            </div>
            {/* Secțiune SEO */}
            <div className="mb-4">
              <h2 className="text-xl font-semibold mb-2">SEO</h2>
              <button
                onClick={handleGenerateSEO}
                className="bg-purple-500 text-white font-bold py-2 px-4 rounded mb-4"
                disabled={seoLoading}
              >
                {seoLoading ? "Generare SEO..." : "Generează SEO prin AI"}
              </button>
              <div className="mb-2">
                <label className="block text-gray-700">Meta Title</label>
                <input
                  type="text"
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder="Meta Title"
                />
              </div>
              <div className="mb-2">
                <label className="block text-gray-700">Slug</label>
                <input
                  type="text"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder="Slug (URL)"
                />
              </div>
              <div className="mb-2">
                <label className="block text-gray-700">Meta Description</label>
                <textarea
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder="Meta Description"
                ></textarea>
              </div>
              <div className="mb-2">
                <label className="block text-gray-700">Meta Keywords</label>
                <input
                  type="text"
                  value={metaKeywords}
                  onChange={(e) => setMetaKeywords(e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder="Meta Keywords (separate cu virgulă)"
                />
              </div>
            </div>
            {/* Secțiune imagine de copertă */}
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">
                Imagine de copertă
              </label>
              {coverImage ? (
                <img src={coverImage} alt="Cover" className="mb-2 max-w-xs" />
              ) : null}
              <button
                type="button"
                onClick={handleCoverUpload}
                className="bg-indigo-500 text-white font-bold py-2 px-4 rounded"
              >
                {coverImage
                  ? "Modifică imaginea de copertă"
                  : "Adaugă imagine de copertă"}
              </button>
              <input
                type="file"
                accept="image/*"
                ref={coverInputRef}
                style={{ display: "none" }}
                onChange={onCoverFileChange}
              />
            </div>
            {/* Secțiune categorii */}
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">
                Categorii
              </label>
              <select
                value=""
                onChange={(e) => {
                  const cat = e.target.value;
                  if (cat && !categories.includes(cat)) {
                    setCategories([...categories, cat]);
                  }
                }}
                className="w-full p-2 border rounded"
              >
                <option value="">Selectează o categorie</option>
                {availableCategories
                  .filter((cat) => !categories.includes(cat))
                  .map((cat, index) => (
                    <option key={index} value={cat}>
                      {cat}
                    </option>
                  ))}
              </select>
              {categories.length > 0 && (
                <div className="mt-2">
                  <p className="text-gray-700">Categorii selectate:</p>
                  <div className="flex flex-wrap gap-2 mt-1">
                    {categories.map((cat, index) => (
                      <span
                        key={index}
                        className="bg-blue-200 text-blue-800 px-2 py-1 rounded flex items-center"
                      >
                        {cat}
                        <button
                          onClick={() =>
                            setCategories(categories.filter((c) => c !== cat))
                          }
                          className="ml-1 text-red-500 font-bold"
                        >
                          x
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </motion.div>
          <motion.div
            className="w-full lg:w-64 xl:w-64 2xl:w-64 bg-white shadow-md rounded-lg p-6 flex-shrink-0"
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Statistici</h3>
              <ul className="text-sm text-gray-700 space-y-1">
                <li>Cuvinte: {postStats.wordCount}</li>
                <li>Caractere: {postStats.charCount}</li>
                <li>Imagini: {postStats.images}</li>
                <li>Link-uri: {postStats.links}</li>
              </ul>
            </div>
            {status === "Draft" ? (
              <div className="mb-4">
                <button
                  type="button"
                  onClick={handlePublish}
                  className="bg-blue-600 text-white font-bold py-2 px-4 rounded w-full hover:bg-blue-700"
                >
                  Publică Postarea
                </button>
              </div>
            ) : (
              <>
                <div className="mb-4">
                  <button
                    type="button"
                    onClick={handlePublish}
                    className="bg-blue-600 text-white font-bold py-2 px-4 rounded w-full hover:bg-blue-700"
                  >
                    Modifică Postarea
                  </button>
                </div>
                <div className="mb-4">
                  <button
                    type="button"
                    onClick={togglePublication}
                    className="bg-orange-600 text-white font-bold py-2 px-4 rounded w-full hover:bg-orange-700"
                  >
                    Schimbă stare:{" "}
                    {status === "Publicată" ? "Fă Draft" : "Publică"}
                  </button>
                </div>
                <div className="mb-4">
                  <span className="block text-gray-700 font-bold">Status:</span>
                  <span className="text-green-600">{status}</span>
                </div>
                <div className="mb-4">
                  <a
                    href={postLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline"
                  >
                    Vizualizează postarea
                  </a>
                </div>
              </>
            )}
            <div>
              <button
                type="submit"
                className="bg-gray-500 text-white font-bold py-2 px-4 rounded w-full hover:bg-gray-600"
              >
                {status === "Draft"
                  ? "Salvează (Draft)"
                  : "Salvează modificările"}
              </button>
            </div>
          </motion.div>
        </div>
      </form>

      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={onFileChange}
      />

      {showCropModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <motion.div
            className="bg-white p-4 rounded-lg"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <h2 className="text-xl font-bold mb-4">Decupează imaginea</h2>
            {imageSrc && (
              <ReactCrop
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                onComplete={(c) => setCompletedCrop(c)}
              >
                <img
                  src={imageSrc}
                  alt="Crop"
                  onLoad={onImageLoaded}
                  style={{ maxWidth: "400px" }}
                />
              </ReactCrop>
            )}
            <div className="mt-4 flex justify-end space-x-2">
              <button
                className="bg-gray-300 text-gray-800 font-bold py-1 px-3 rounded"
                onClick={() => {
                  setShowCropModal(false);
                  setImageSrc(null);
                }}
              >
                Anulează
              </button>
              <button
                className="bg-green-500 text-white font-bold py-1 px-3 rounded"
                onClick={insertCroppedImage}
              >
                Decupează și inserează
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
}

export default AddPost;
