import React, { useState } from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import nutritieImage from "../static/images/nutrition-info.jpg";
import axios from "axios";
import { api_url } from "../constants";
const NutritiePage = () => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const phone = e.target.phone.value;

    if (name.length == 0 || phone.length == 0) {
      alert("Va rugam sa completati toate campurile.");
      return;
    }

    const data = {
      name,
      phone,
      service: "Nutritie",
    };
    if (e.target.email.value) {
      data.email = e.target.email.value;
    }
    setIsFormSubmitting(true);
    axios
      .post(`${api_url}/appointment/`, data)
      .then((res) => {
        setIsFormSuccess(true);
        setIsFormSubmitting(false);
      })
      .catch((err) => {
        alert("A aparut o eroare. Va rugam sa incercati din nou.");
        setIsFormSubmitting(false);
      });
  };

  return (
    <div>
      <Helmet>
        <title>
          Nutriție Personalizată - Transformă-ți corpul și recâștigă-ți energia
          | Stop Durere
        </title>
        <meta
          name="description"
          content="Beneficiază de un plan alimentar personalizat, adaptat nevoilor tale, și transformă-ți viața prin nutriție sănătoasă. Programează-te acum!"
        />
        <link rel="canonical" href="https://www.stopdurere.md/nutritie" />
      </Helmet>

      {/* Secțiunea Hero */}
      <section className="bg-primary text-white py-20 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <h1 className="text-5xl md:text-4xl sm:text-3xl font-extrabold mb-4">
          Transformă-ți corpul și recâștigă-ți energia cu o nutriție
          personalizată!
        </h1>
        <h2 className="text-3xl md:text-2xl sm:text-xl font-bold mb-6">
          Nutriționist certificat cu experiență vastă în transformarea vieților
          prin alimentație sănătoasă.
        </h2>
        <p className="text-lg sm:text-base mb-2 font-bold">
          Ai obosit de diete restrictive și rezultate temporare? Descoperă o
          nutriție personalizată, adaptată nevoilor tale, și bucură-te de o
          viață plină de energie și vitalitate!
        </p>{" "}
        <p className="text-lg mb-8 sm:text-base">
          Tot ce trebuie să faci este să lași datele de contact. Apoi, asistenta
          te va contacta să te înscrie la o programare.
        </p>
        <a href="#appointment">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-secondary text-white px-10 py-3 rounded-lg font-bold"
          >
            Programează-te acum
          </motion.button>
        </a>
      </section>

      {/* Secțiunea de descriere */}
      <section className="py-12 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <img
          src={nutritieImage}
          alt="Nutriție Personalizată"
          className="w-full mb-6 rounded-xl drop-shadow-md"
        />
        <div className="mb-12">
          <p className="text-lg md:text-lg sm:text-base font-medium mb-4">
            Te simți lipsit de energie și obosit constant? Te lupți cu
            kilogramele în plus și nu știi ce să mai faci? Ai pofte necontrolate
            și te simți vinovat după fiecare masă? Te simți confuz în fața
            avalanșei de informații contradictorii despre nutriție? Ai probleme
            digestive, piele ternă sau alte simptome care îți afectează starea
            de bine? Te simți frustrat și neputincios, parcă ai fi captiv
            într-un corp care nu te mai ascultă? Te confrunți cu sindromul
            colonului iritabil, boala celiacă, intoleranță la lactoză, boala
            Crohn? Ai afecțiuni cardiovasculare, probleme tiroidiene, reflux
            gastroesofagian și tulburări de alimentație (anorexie, bulimie)?
          </p>
          <p className="text-lg mb-4 md:text-lg sm:text-base font-medium">
            Îți amintești ultima oară când te-ai simțit plin de energie, când
            te-ai bucurat de mâncare fără vinovăție, când te-ai simțit bine în
            pielea ta? Poate că a fost acum ani de zile, înainte ca stresul,
            poftele și kilogramele în plus să-ți fure bucuria de a trăi.
          </p>{" "}
          <p className="text-lg mb-4  md:text-lg sm:text-base font-medium">
            Îți amintești zilele în care te trezeai cu un entuziasm debordant,
            gata să cucerești lumea? Zilele în care te simțeai ușor și plin de
            vitalitate, când mâncarea era o plăcere, nu un dușman? Ei bine,
            acele zile nu trebuie să rămână doar o amintire!
          </p>
          <p className="text-lg mb-4 md:text-lg sm:text-base font-medium">
            Poate ai încercat diverse diete, dar te-ai simțit mereu copleșit de
            restricții și de lipsa de rezultate pe termen lung. Poate ai simțit
            că trăiești într-o lume în care mâncarea este un dușman, nu un
            aliat.
          </p>
          <p className="text-lg mb-4 md:text-lg sm:text-base font-medium">
            Imaginează-ți acum că te trezești dimineața plin de energie, gata să
            cucerești lumea. Imaginează-ți că te bucuri de mese delicioase și
            sănătoase, fără să te simți vinovat. Imaginează-ți că te simți bine
            în pielea ta, plin de încredere și vitalitate.
          </p>
          <p className="text-lg mb-4 md:text-lg sm:text-base font-medium">
            Sănătatea depinde de mai mulți factori, inclusiv dieta zilnică. O
            alimentație echilibrată și variată poate preveni afecțiuni precum
            obezitatea, diabetul, bolile de inimă și cancerul. Consumul adecvat
            de vitamine și minerale întărește imunitatea, menține sănătatea
            pielii și a organelor. Nutriția este esențială pentru dezvoltarea
            fizică și cognitivă, mai ales în copilărie și adolescență.
            Consultația nutrițională creează un plan alimentar personalizat,
            adaptat la sănătatea, greutatea și preferințele fiecarei persoane,
            oferind ghidare și susținere pe durata programului.
          </p>
          <p className="text-lg sm:text-base font-medium">
            Consultanța nutrițională personalizată îți oferă soluții adaptate
            nevoilor tale, bazate pe principii științifice și pe o abordare
            holistică. Împreună, vom crea un plan alimentar personalizat, care
            să te ajute să-ți atingi obiectivele de sănătate și greutate, să-ți
            îmbunătățești digestia, să-ți crești nivelul de energie și să te
            simți bine în pielea ta. Se va monitoriza in timp respectarea
            planului alimentar personalizat, care are menirea corectarea
            metabolismului și înlăturarea cauzelor dereglării lui.
          </p>
        </div>
        <div className="mb-8">
          <h3 className="text-2xl md:text-xl sm:text-lg font-bold mb-4">
            Beneficii:
          </h3>
          <ul className="list-disc list-inside mt-4 text-lg md:text-base sm:text-sm">
            <li>Plan alimentar personalizat, adaptat nevoilor tale.</li>
            <li>Îmbunătățirea digestiei și a nivelului de energie.</li>
            <li>Ameliorarea dezechilibrelor metabolice.</li>
            <li>
              Gestionarea greutății și atingerea obiectivelor de sănătate.
            </li>
            <li>Reducerea poftei de dulce și a poftei necontrolate.</li>
            <li>Îmbunătățirea stării de spirit și a calității somnului.</li>
            <li>Suport și motivație constantă pe parcursul procesului.</li>
          </ul>
        </div>
        <div className="mb-8">
          <h3 className="text-2xl md:text-xl sm:text-lg font-bold mb-4">
            Bonusuri
          </h3>
          <ul className="list-disc list-inside mt-4 text-lg md:text-base sm:text-sm">
            <li>Rețete delicioase și sănătoase.</li>
            <li>
              Programează-te acum și vei primi 20% discount la pachetul de
              consultanță!
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h3 className="text-2xl font-bold mb-4 lg:text-xl sm:text-lg">
            Acționează acum!
          </h3>
          <p className="text-lg mb-4 sm:text-base">
            Nu lăsa obiceiurile nesănătoase să-ți controleze viața. Completează
            formularul de mai jos și fă primul pas spre o viață plină de energie
            și vitalitate!
          </p>
        </div>
      </section>

      <a id="appointment"></a>

      {/* Formularul de programare */}
      <section className="bg-gray-100 py-20 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <h3 className="text-3xl md:text-2xl sm:text-xl font-bold text-center mb-8">
          Programează-te pentru Consultanța Nutrițională
        </h3>
        {isFormSuccess ? (
          <div className="text-center">
            <p className="text-xl md:text-lg sm:text-base font-medium text-green-600">
              Mulțumim pentru programare! Vei fi contactat în cel mai scurt timp
              posibil.
            </p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="max-w-lg mx-auto flex flex-col gap-y-6"
          >
            <input
              type="text"
              name="name"
              placeholder="Nume complet"
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Număr de telefon"
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
            <input
              type="text"
              name="email"
              placeholder="Email (opțional)"
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
            />
            <motion.button
              type="submit"
              disabled={isFormSubmitting}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-primary text-white text-xl md:text-lg sm:text-base rounded-lg font-bold px-10 py-3"
            >
              {isFormSubmitting ? "Se trimite..." : "Programează-te"}
            </motion.button>
          </form>
        )}
      </section>
    </div>
  );
};

export default NutritiePage;
