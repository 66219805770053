import React, { useState } from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { api_url } from "../constants";
import pdtrImage from "../static/images/pdtr-info.jpg";
const PDTRLandingPage = () => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const phone = e.target.phone.value;

    if (name.length == 0 || phone.length == 0) {
      alert("Va rugam sa completati toate campurile.");
      return;
    }

    const data = {
      name,
      phone,
      service: "P-DTR",
    };
    if (e.target.email.value) {
      data.email = e.target.email.value;
    }
    setIsFormSubmitting(true);
    axios
      .post(`${api_url}/appointment/`, data)
      .then((res) => {
        setIsFormSuccess(true);
        setIsFormSubmitting(false);
      })
      .catch((err) => {
        alert("A aparut o eroare. Va rugam sa incercati din nou.");
        setIsFormSubmitting(false);
      });
  };

  return (
    <div>
      <Helmet>
        <title>
          P-DTR - Terapia nedureroasă pentru eliberarea de durere | Stop Durere
        </title>
        <meta
          name="description"
          content="Eliberează-te de durere prin terapia P-DTR. Terapie nedureroasă, eficientă și de durată pentru problemele tale de spate, cervicale, genunchi și multe altele."
        />
        <link rel="canonical" href="https://www.stopdurere.md/terapia-pdt-r" />
      </Helmet>
      {/* Secțiunea de tip Hero */}
      <section className="bg-primary text-white py-20 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <h1 className="text-5xl md:text-4xl sm:text-3xl font-extrabold mb-4">
          Eliberează-te de durere și bucură-te viața!
        </h1>
        <h2 className="text-3xl font-bold mb-6 md:text-2xl sm:text-xl">
          Primul terapeut P-DTR cu experiență din Moldova te așteaptă!
        </h2>
        <p className="text-lg mb-2 sm:text-base font-bold">
          Dacă ai obosit de dureri care te supără de ani de zile și nu mai știi
          ce să mai faci și unde să te adresezi, vino la câteva ședințe de
          terapie și te vei elibera de dureri.
        </p>
        <p className="text-lg mb-8 sm:text-base">
          Tot ce trebuie să faci este să lași datele de contact. Apoi, asistenta
          te va contacta să te înscrie la o programare.
        </p>
        <a href="#appointment">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-secondary text-white px-10 py-3 rounded-lg font-bold"
          >
            Programează-te acum
          </motion.button>
        </a>
      </section>
      {/* Secțiunea de descriere și motivație */}
      <section className="py-12 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <img
          src={pdtrImage}
          alt="P-DTR"
          className="w-full mb-6 rounded-xl drop-shadow-md"
        />
        <div className="mb-12">
          <p className="text-lg font-medium mb-4 md:text-lg sm:text-base">
            Ai obosit să ai dureri de spate, cervicale, de umeri, de genunchi,
            de glezne, de cap? Te doare spatele când lucrezi, stai așezat și
            culcat? Te-ai săturat de dureri cronice care îți limitează viața?
            Nopțile sunt un chin, găsirea unei poziții confortabile fiind o
            misiune imposibilă? Te simți tensionat, stresat, ca și cum ai purta
            o greutate constantă pe umeri? Urcatul scărilor, mersul pe jos,
            chiar și statul în picioare devin activități dureroase? Te simți
            frustrat, neputincios, parcă ai fi captiv într-un corp care nu te
            mai ascultă?
          </p>
          <p className="text-lg mb-4 font-medium md:text-lg sm:text-base">
            Îți amintești ultima oară când te-ai trezit fără durere? Când te-ai
            simțit liber să te miști, să te bucuri de viață fără teama că
            fiecare pas va fi un chin? Poate că a fost acum ani de zile, înainte
            ca durerile cronice să-ți fure bucuria de a trăi. Ai încercat totul:
            pastile, masaje, fizioterapie. Dar durerea revine mereu, ca un
            musafir nepoftit. Te simți ca și cum trăiești într-un corp care te
            trădează, care nu mai răspunde la comenzi.
          </p>
          <p className="text-lg font-medium md:text-lg sm:text-base">
            Imaginează-ți acum că te trezești dimineața și te simți odihnit,
            fără nicio durere. Imaginează-ți că poți urca scările fără să simți
            că genunchii te lasă, că poți merge pe jos fără să te oprești din
            cauza durerii de spate. Imaginează-ți că poți lucra, te poți juca cu
            copiii, poți face sport, poți trăi din nou!
          </p>
        </div>
        {/* Secțiunea ce explică terapia P-DTR */}
        <div className="mb-10">
          <h3 className="text-2xl font-bold mb-4">Ce este P-DTR?</h3>
          <p className="text-lg mb-4 sm:text-base">
            P-DTR este o terapie nedureroasă, se aplică la toate categoriile de
            vârstă și, cel mai important, rezultatul se resimte imediat și este
            de durată. Această tehnică este utilă pentru probleme FUNCTIONALE.
            Tratamentul va afecta în mod benefic sistemul nervos al organismului
            în general și următoarele sisteme în particular: digestiv,
            musculo-scheletic, circulator și limfatic. Terapia este eficientă în
            tratarea unei game variate de afecțiuni ale aparatului
            scheleto-muscular, oferind soluții pentru:
          </p>
          <ul className="list-disc list-inside mt-4 text-lg sm:text-base mb-6">
            <li>
              <strong>Dureri articulare:</strong> Elimină disconfortul și redă
              mobilitatea articulațiilor afectate.
            </li>
            <li>
              <strong>Dureri musculare:</strong> Reduce tensiunea musculară,
              contracturile și spasmele, ameliorând durerea.
            </li>
            <li>
              <strong>Tensiune și disconfort muscular:</strong> Relaxare
              profundă a musculaturii, eliminând senzația de încordare și
              disconfort.
            </li>
            <li>
              <strong>Urmări ale traumelor:</strong> Accelerează procesul de
              recuperare după accidente, căzături sau lovituri.
            </li>
            <li>
              <strong>Fracturi și dislocări:</strong> Sprijină refacerea
              țesuturilor și restabilirea funcționalității zonei afectate.
            </li>
            <li>
              <strong>Sindromul durerii posttraumatice:</strong> Ameliorează
              durerea persistentă și disconfortul după leziuni.
            </li>
            <li>
              <strong>Îmbunătățirea mobilității articulare:</strong> Redă
              flexibilitatea și amplitudinea de mișcare a articulațiilor.
            </li>
          </ul>
          <p className="text-lg mb-4 sm:text-base">
            Această terapie se adresează persoanelor care se confruntă cu:
          </p>
          <ul className="list-disc list-inside mt-4 text-lg sm:text-base">
            <li>
              Dureri cronice sau acute ale spatelui, gâtului, umerilor,
              genunchilor, gleznelor etc.
            </li>
            <li>
              Limitări ale mișcării și dificultăți în efectuarea activităților
              zilnice.
            </li>
            <li>Recuperare după intervenții chirurgicale sau accidente.</li>
            <li>Tensiune musculară cauzată de stres sau postură incorectă.</li>
          </ul>
        </div>
        {/* Secțiunea de beneficii și bonusuri */}
        <div className="mb-8">
          <h3 className="text-2xl lg:text-xl sm:text-lg font-bold mb-4">
            Beneficii
          </h3>
          <ul className="list-disc list-inside mt-4 text-lg sm:text-base">
            <li>Chiar după prima ședință vei simți o ușurare.</li>
            <li>O ședință P-DTR tratează cât 4 ședințe de alte terapii.</li>
            <li>Vei scăpa de dureri.</li>
            <li>Vei scăpa de dureri, discomfort.</li>
            <li>Vei simți mai multă energie.</li>
          </ul>
        </div>
        <div className="mb-8">
          <h3 className="text-2xl font-bold mb-4 lg:text-xl sm:text-lg">
            Bonusuri
          </h3>
          <ul className="list-disc list-inside mt-4 text-lg sm:text-base">
            <li>Consultație gratuită.</li>
            <li>Programează-te acum și vei primi 20% discount.</li>
          </ul>
        </div>
        <div className="mb-4">
          <h3 className="text-2xl font-bold mb-4 lg:text-xl sm:text-lg">
            Acționează acum!
          </h3>
          <p className="text-lg mb-4 sm:text-base">
            Nu lăsa durerea să-ți controleze viața. Completează formularul de
            mai jos și fă primul pas spre o viață fără durere!
          </p>
        </div>
        {/* Call-to-Action final */}
        {/* <div className="text-center mt-12">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-primary text-white text-xl rounded-lg font-bold px-10 py-3"
          >
            Acționează acum!
          </motion.button>
        </div> */}
      </section>
      <a id="appointment"></a>
      {/* Formularul de programare */}
      <section className="bg-gray-100 py-20 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <h3 className="text-3xl font-bold text-center mb-8 lg:text-2xl sm:text-xl">
          Programează-te pentru P-DTR
        </h3>
        {isFormSuccess ? (
          <div className="text-center">
            <p className="text-xl font-medium text-green-600 lg:text-lg sm:text-base">
              Mulțumim pentru programare! Vei fi contactat în cel mai scurt timp
              posibil.
            </p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="max-w-lg mx-auto flex flex-col gap-y-6"
          >
            <input
              type="text"
              name="name"
              placeholder="Nume complet"
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Număr de telefon"
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
            <input
              type="text"
              name="email"
              placeholder="Email (opțional)"
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
            />
            <motion.button
              type="submit"
              disabled={isFormSubmitting}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-primary text-white text-xl rounded-lg font-bold px-10 py-3 lg:text-lg sm:text-base"
            >
              {isFormSubmitting ? "Se trimite..." : "Programează-te"}
            </motion.button>
          </form>
        )}
      </section>
    </div>
  );
};

export default PDTRLandingPage;
