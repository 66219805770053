import React from "react";
import AdminLayout from "./screens/AdminLayout";
import Home from "./screens/Home";
import AddPost from "./screens/AddPost";
import Categories from "./screens/Categories";
import PostList from "./screens/PostList";
import EditPost from "./screens/EditPost";
import PostDetail from "./screens/PostDetail";
import CategoryPosts from "./screens/CategoryPosts";
import AppointmentList from "./screens/AppointmentList";
import AllPosts from "./screens/AllPosts";
import UserLayout from "./screens/UserLayout";
import PDTRLandingPage from "./screens/PDTRLandingPage";
import AromaterapieBachPage from "./screens/AromaterapieBachPage";
import NutritiePage from "./screens/NutritiePage";
import Promotion from "./screens/Promotion";
import { Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<UserLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/posts/:slug" element={<PostDetail />} />
          <Route
            path="/posts/category/:category_name"
            element={<CategoryPosts />}
          />
          <Route path="/posts" element={<AllPosts />} />
          <Route path="/terapia-pdt-r" element={<PDTRLandingPage />} />
          <Route path="/aromaterapie-bach" element={<AromaterapieBachPage />} />
          <Route path="/nutritie" element={<NutritiePage />} />
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          <Route path="appointments" element={<AppointmentList />} />
          <Route path="add-post" element={<AddPost />} />
          <Route path="categories" element={<Categories />} />
          <Route path="posts" element={<PostList />} />
          <Route path="edit-post/:id" element={<EditPost />} />
          <Route path="promotion" element={<Promotion />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
