import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url } from "../constants";
import { useNavigate, useParams } from "react-router-dom";

function PostList() {
  const [posts, setPosts] = useState([]);
  const [sortField, setSortField] = useState("-created_at");
  const [errorMessage, setErrorMessage] = useState("");

  // Funcția de preluare a postărilor
  const fetchPosts = async () => {
    try {
      const response = await axios.get(api_url + "/get-posts", {
        params: { sort: sortField },
      });
      setPosts(response.data);
    } catch (error) {
      setErrorMessage("Eroare la preluarea postărilor.");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [sortField]);

  // Funcția de ștergere a unei postări
  const handleDelete = async (postId) => {
    if (window.confirm("Sigur dorești să ștergi această postare?")) {
      try {
        const code = localStorage.getItem("code");
        await axios.delete(api_url + `/delete-post/${postId}`, {
          headers: { Authorization: `Bearer ${code}` },
        });
        fetchPosts();
      } catch (error) {
        setErrorMessage("Eroare la ștergerea postării.");
        console.error(error);
      }
    }
  };
  const navigate = useNavigate();

  // Funcția de navigare spre pagina de editare a postării
  const handleEdit = (postId) => {
    // Redirecționează către pagina de editare; asigură-te că ruta există în router-ul tău
    navigate(`/admin/edit-post/${postId}`);
  };

  return (
    <div className="w-full mx-auto p-4 mt-4">
      <h1 className="text-2xl font-bold mb-2 md:text-xl sm:text-xl">
        Lista Postărilor
      </h1>

      {errorMessage && (
        <div className="bg-red-200 text-red-800 p-2 mb-4 rounded">
          {errorMessage}
        </div>
      )}

      <div className="mb-4">
        <label className="mr-2 font-semibold">Sortează după:</label>
        <select
          value={sortField}
          onChange={(e) => setSortField(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="-created_at">Data (descrescător)</option>
          <option value="created_at">Data (crescător)</option>
          <option value="title">Titlu</option>
        </select>
      </div>

      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Titlu</th>
            <th className="py-2 px-4 border-b lg:hidden md:hidden sm:hidden">
              Slug
            </th>
            <th className="py-2 px-4 border-b sm:hidden">Status</th>
            <th className="py-2 px-4 border-b md:hidden sm:hidden">
              Data creare
            </th>
            <th className="py-2 px-4 border-b">Acțiuni</th>
          </tr>
        </thead>
        <tbody>
          {posts.map((post) => (
            <tr key={post.id}>
              <td
                className="py-2 px-4 border-b cursor-pointer text-blue-600"
                onClick={() => handleEdit(post.id)}
              >
                {post.title}
              </td>
              <td className="py-2 px-4 border-b lg:hidden md:hidden sm:hidden">
                {post.slug}
              </td>
              <td className="py-2 px-4 border-b sm:hidden">
                {post.is_draft ? "Draft" : "Publicată"}
              </td>
              <td className="py-2 px-4 border-b md:hidden sm:hidden">
                {new Date(post.created_at).toLocaleDateString()}
              </td>
              <td className="py-2 px-4 border-b gap-y-2 flex gap-x-2 sm:flex-col md:flex-col">
                <button
                  onClick={() => handleEdit(post.id)}
                  className="bg-blue-500 text-white py-1 w-full px-3 rounded mr-2"
                >
                  Modifică
                </button>
                <button
                  onClick={() => handleDelete(post.id)}
                  className="bg-red-500 text-white py-1 w-full px-3 rounded"
                >
                  Șterge
                </button>
              </td>
            </tr>
          ))}
          {posts.length === 0 && (
            <tr>
              <td colSpan="5" className="text-center py-4">
                Nu există postări.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PostList;
