import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { api_url } from "../constants";

const API_URL = api_url + "/promotions/";

const Promotion = () => {
  const [promotions, setPromotions] = useState([]);
  const [content, setContent] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [editingId, setEditingId] = useState(null);

  // Preia promoțiile din backend
  const fetchPromotions = async () => {
    try {
      const response = await axios.get(API_URL);
      setPromotions(response.data);
    } catch (error) {
      console.error("Eroare la preluarea promoțiilor:", error);
    }
  };

  useEffect(() => {
    fetchPromotions();
  }, []);

  // Gestionează submit-ul pentru creare/actualizare
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { content, is_active: isActive };

    try {
      if (editingId) {
        // Actualizare
        await axios.put(`${API_URL}${editingId}/`, payload);
        setEditingId(null);
      } else {
        // Creare
        await axios.post(API_URL, payload);
      }
      setContent("");
      setIsActive(false);
      fetchPromotions();
    } catch (error) {
      console.error("Eroare la salvare:", error);
    }
  };

  // Pregătește o promoție pentru editare
  const handleEdit = (promotion) => {
    setContent(promotion.content);
    setIsActive(promotion.is_active);
    setEditingId(promotion.id);
  };

  // Șterge o promoție
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}${id}/`);
      fetchPromotions();
    } catch (error) {
      console.error("Eroare la ștergere:", error);
    }
  };

  return (
    <div className="max-w-3xl mt-4 p-6">
      <h2 className="text-2xl md:text-xl sm:text-xl font-bold text-left mb-2">
        Gestionare Promoții
      </h2>

      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded shadow mb-8"
      >
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Conținut
          </label>
          <textarea
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="activeCheck"
            className="mr-2"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
          <label htmlFor="activeCheck" className="text-gray-700">
            Promoție activă
          </label>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded transition-colors duration-200"
        >
          {editingId ? "Actualizează Promoția" : "Adaugă Promoția"}
        </button>
      </form>

      <h3 className="text-2xl font-semibold mb-4">Lista Promoțiilor</h3>
      <ul className="space-y-4">
        <AnimatePresence>
          {promotions.map((promo) => (
            <motion.li
              key={promo.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, x: -20 }}
              className="bg-white p-4 rounded shadow flex justify-between items-center"
            >
              <div>
                <p className="text-gray-800">
                  <span className="font-semibold">Conținut:</span>{" "}
                  {promo.content}
                </p>
                <p className="text-gray-600">
                  <span className="font-semibold">Status:</span>{" "}
                  {promo.is_active ? "Activă" : "Inactivă"}
                </p>
              </div>
              <div className="flex space-x-3">
                <button
                  onClick={() => handleEdit(promo)}
                  className="text-blue-500 hover:text-blue-700"
                  title="Editează"
                >
                  <FaEdit size={20} />
                </button>
                <button
                  onClick={() => handleDelete(promo.id)}
                  className="text-red-500 hover:text-red-700"
                  title="Șterge"
                >
                  <FaTrash size={20} />
                </button>
              </div>
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
    </div>
  );
};

export default Promotion;
