// AllPosts.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url, media_url } from "../constants";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function AllPosts() {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10; // se afișează 10 postări pe pagină

  // Funcție pentru a elimina tag-urile HTML folosind DOMParser
  const stripHtmlTags = (html) => {
    if (!html) return "";
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  // Funcție pentru a extrage un excerpt cu o limită de caractere (default: 150)
  const getExcerpt = (htmlContent, limit = 150) => {
    const text = stripHtmlTags(htmlContent);
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${api_url}/posts/?page=${currentPage}`)
      .then((response) => {
        // Se presupune că răspunsul vine sub forma: { posts: [...], totalPages: N }
        setPosts(response.data.posts);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response?.data?.error || error.message);
        setLoading(false);
      });
  }, [currentPage]);

  if (error) {
    return <div className="max-w-4xl mx-auto p-6 text-red-500">{error}</div>;
  }

  if (loading) {
    // Afișăm un loading grid cu skeleton
    const skeletonItems = Array.from({ length: 6 });
    return (
      <div className="w-full mx-auto p-6 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <h1 className="text-3xl font-bold mb-6 sm:text-xl md:text-2xl">
          Toate Postările
        </h1>
        <div className="grid grid-cols-2 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-3 gap-6">
          {skeletonItems.map((_, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-4">
              <Skeleton height={192} className="rounded-t-lg" />
              <div className="mt-4">
                <Skeleton height={30} width={`80%`} />
              </div>
              <div className="mt-2">
                <Skeleton count={3} />
              </div>
              <div className="mt-2">
                <Skeleton height={20} width={`60%`} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (!loading && posts.length === 0) {
    // Mesaj stilat când nu există postări
    return (
      <div className="max-w-4xl mx-auto p-6 text-center text-gray-700">
        <h1 className="text-3xl font-bold mb-4 sm:text-xl md:text-2xl">
          Toate Postările
        </h1>
        <p className="text-xl font-semibold md:text-lg sm:text-base">
          Nu există postări.
        </p>
      </div>
    );
  }

  return (
    <div className="w-full mx-auto p-6 px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
      <h1 className="text-3xl font-bold mb-6 sm:text-xl md:text-2xl">
        Toate Postările
      </h1>
      <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-1 gap-6">
        {posts.map((post) => (
          <Link
            key={post.id}
            to={`/posts/${post.slug}`}
            className="block bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            {post.cover_image && (
              <img
                src={media_url + post.cover_image}
                alt={post.title}
                className="w-full h-48 object-cover rounded-t-lg"
              />
            )}
            <div className="p-4">
              <h2 className="text-2xl font-semibold mb-2">{post.title}</h2>
              <p className="text-gray-700">
                {getExcerpt(post.excerpt || post.content, 150)}
              </p>
              <p className="text-sm text-gray-500 mt-2">
                Publicat pe: {new Date(post.created_at).toLocaleDateString()}
              </p>
            </div>
          </Link>
        ))}
      </div>
      {totalPages > 1 && (
        <div className="flex justify-center mt-8">
          <button
            onClick={() =>
              setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))
            }
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-300 rounded-l disabled:opacity-50"
          >
            Anterior
          </button>
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={`px-4 py-2 border-t border-b ${
                index + 1 === currentPage
                  ? "bg-blue-500 text-white"
                  : "bg-gray-100 text-gray-700"
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() =>
              setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev))
            }
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-gray-300 rounded-r disabled:opacity-50"
          >
            Următorul
          </button>
        </div>
      )}
    </div>
  );
}

export default AllPosts;
